import React from "react";
import Modal from "../components/modals/BaseModal";
import ButtonRow from "../components/buttons/ButtonRow";
import LargeOutlineButton from "../components/buttons/LargeOutlineButton";
import EditableEmoji from "../components/elements/emojis/EditableEmoji";
import LargeDarkButton from "../components/buttons/LargeDarkButton";
import styled from "styled-components";
import OutlineInput from "../components/inputs/OutlineInput";
import LargeMainButton from "../components/buttons/LargeMainButton";

const BodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
`;

const ButtonWrapper = styled.div`
  margin-right: 5px;
  display: flex;
  align-items: center;
  position: relative;
  justify-content: center;
`;

const EmojiPickerWrapper = styled.div`
  position: fixed;
  z-index: 15;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;

const RenameTeamModal = ({
  teamName,
  emoji,
  emojiPicker,
  onShowEmojiPicker,
  onTeamNameChange,
  onRename,
  onCancel,
}) => {
  return (
    <>
      <Modal
        title="Rename Team"
        body={
          <BodyWrapper>
            <ButtonWrapper>
              {emoji ? (
                <EditableEmoji
                  onClick={onShowEmojiPicker}
                  emoji={emoji}
                  size={"xlarge"}
                />
              ) : (
                <LargeDarkButton onClick={onShowEmojiPicker} noSpinner>
                  Choose Emoji
                </LargeDarkButton>
              )}
            </ButtonWrapper>
            <OutlineInput
              name={"name"}
              value={teamName}
              onChange={() => onTeamNameChange(event.target.value)}
            />
          </BodyWrapper>
        }
        buttons={
          <ButtonRow>
            <LargeMainButton onClick={onRename} data-cy-name={"Rename"}>
              Rename
            </LargeMainButton>
            <LargeOutlineButton noSpinner onClick={onCancel}>
              Cancel
            </LargeOutlineButton>
          </ButtonRow>
        }
        onEscapeKey={onCancel}
      />
      {emojiPicker && <EmojiPickerWrapper>{emojiPicker}</EmojiPickerWrapper>}
    </>
  );
};

export default RenameTeamModal;
