import { gql, useMutation } from "@apollo/client";

export default () =>
  useMutation(gql`
    mutation RenameTeam($teamId: ID!, $name: String!, $emoji: String!) {
      renameTeam(teamId: $teamId, name: $name, emoji: $emoji) {
        ok
      }
    }
  `);
