import React, { useCallback, useContext, useEffect, useState } from "react";
import { navigate } from "gatsby";
import QuizContext from "../../../contexts/QuizContext";
import WaitingRoomController from "./01/WaitingRoomController";
import { ServerTimeContextProvider } from "../../../contexts/ServerTimeContext";
import { LocalTracksContextProvider } from "../../contexts/LocalTracksContext";
import { MainRoomContextProvider } from "../../contexts/MainRoomContext";
import ResetQuizController from "./modals/ResetQuizController";
import {
  ANSWERS,
  FACTS,
  MEET_TEAMS,
  NAME_TEAMS,
  QUESTION,
  QUESTION_RESULTS,
  QUIZ_CONVERSATION,
  QUIZ_INTRO,
  QUIZ_RESULTS,
  ROUND_INTRO,
  ROUND_RESULTS,
  TIEBREAKER,
  TIEBREAKER_ANSWERS,
  TIEBREAKER_RESULTS,
} from "../../constants";
import QuizIntroController from "./02/QuizIntroController";
import NameTeamsController from "./03/NameTeamsController";
import MeetTeamsController from "./04/MeetTeamsController";
import RoundIntroController from "./05/RoundIntroController";
import QuestionController from "./06/QuestionController";
import AnswersController from "./07/AnswersController";
import QuestionResultsController from "./08/QuestionResultsController";
import FactsController from "./09/FactsController";
import RoundResultsController from "./10/RoundResultsController";
import QuizResultsController from "./11/QuizResultsController";
import TiebreakerController from "./12/TiebreakerController";
import TiebreakerAnswersController from "./13/TiebreakerAnswersController";
import TiebreakerResultsController from "./14/TiebreakerResultsController";
import QuizConversationController from "./15/QuizConversationController";
import useQuizWasResetEffect from "./useQuizWasResetEffect";
import useQuizWasEndedEffect from "./useQuizWasEndedEffect";
import { EVENT_RESULTS } from "../../../constants";
import SpeakingWhileMutedController from "./SpeakingWhileMutedController";
import { WindowContextProvider } from "../../contexts/WindowContext";
import SessionDataContext from "../../../contexts/SessionDataContext";
import LateJoinController from "./LateJoinController";
import usePlayerWasRemovedEffect from "./usePlayerWasRemovedEffect";
import { QuizNavigationContextProvider } from "../../contexts/QuizNavigationContext";
import { SettingsContextProvider } from "../../contexts/SettingsContext";
import { IdentificationContextProvider } from "../../contexts/IdentificationContext";
import LargeDangerButton from "../../components/buttons/LargeDangerButton";

const QuizController = () => {
  const { myPlayerId } = useContext(SessionDataContext);
  const {
    quiz,
    videoToken,
    whatToShow,
    eventWhatToShow,
    myTeam,
    canResetQuiz,
    event,
  } = useContext(QuizContext);
  const roomName = quiz && quiz.id;
  const [showResetQuizModal, setShowResetQuizModal] = useState();

  const handlePlayerWasRemoved = useCallback(() => {
    if (event) {
      navigate("/event/");
    } else {
      navigate("/");
    }
  }, [event]);

  useQuizWasEndedEffect(() => navigate("/survey/"));
  useQuizWasResetEffect(() => navigate("/"));
  usePlayerWasRemovedEffect(handlePlayerWasRemoved);

  useEffect(() => {
    if (eventWhatToShow === EVENT_RESULTS) {
      navigate("/event/");
    }
  }, [eventWhatToShow]);

  const handleShowResetQuizModal = () => setShowResetQuizModal(true);
  const handleResetQuizCancel = () => setShowResetQuizModal(false);

  const controllerToShow = (resetButton) => {
    if (!quiz) {
      return null;
    }

    if (!whatToShow) {
      return <WaitingRoomController resetButton={resetButton} />;
    }

    if (quiz && myPlayerId && !myTeam && whatToShow !== QUIZ_INTRO) {
      return <LateJoinController />;
    }

    switch (whatToShow) {
      case QUIZ_INTRO:
        return <QuizIntroController resetButton={resetButton} />;

      case NAME_TEAMS:
        return <NameTeamsController resetButton={resetButton} />;

      case MEET_TEAMS:
        return <MeetTeamsController resetButton={resetButton} />;

      case ROUND_INTRO:
        return <RoundIntroController resetButton={resetButton} />;

      case QUESTION:
        return <QuestionController resetButton={resetButton} />;

      case ANSWERS:
        return <AnswersController resetButton={resetButton} />;

      case QUESTION_RESULTS:
        return <QuestionResultsController resetButton={resetButton} />;

      case FACTS:
        return <FactsController resetButton={resetButton} />;

      case ROUND_RESULTS:
        return <RoundResultsController resetButton={resetButton} />;

      case QUIZ_RESULTS:
        return <QuizResultsController resetButton={resetButton} />;

      case TIEBREAKER:
        return <TiebreakerController resetButton={resetButton} />;

      case TIEBREAKER_ANSWERS:
        return <TiebreakerAnswersController resetButton={resetButton} />;

      case TIEBREAKER_RESULTS:
        return <TiebreakerResultsController resetButton={resetButton} />;

      case QUIZ_CONVERSATION:
        return <QuizConversationController resetButton={resetButton} />;

      default:
        return null;
    }
  };

  return (
    <ServerTimeContextProvider>
      <WindowContextProvider>
        <IdentificationContextProvider>
          <SettingsContextProvider>
            <QuizNavigationContextProvider>
              <LocalTracksContextProvider>
                <MainRoomContextProvider roomName={roomName} token={videoToken}>
                  <>
                    {controllerToShow(
                      canResetQuiz && (
                        <LargeDangerButton
                          noSpinner
                          onClick={handleShowResetQuizModal}
                          data-cy-name={"Reset Quiz"}
                        >
                          Reset Quiz
                        </LargeDangerButton>
                      )
                    )}
                    {showResetQuizModal && (
                      <ResetQuizController onCancel={handleResetQuizCancel} />
                    )}
                    <SpeakingWhileMutedController />
                  </>
                </MainRoomContextProvider>
              </LocalTracksContextProvider>
            </QuizNavigationContextProvider>
          </SettingsContextProvider>
        </IdentificationContextProvider>
      </WindowContextProvider>
    </ServerTimeContextProvider>
  );
};

export default QuizController;
