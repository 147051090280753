import React, { useState } from "react";
import RenameTeamModal from "../../../modals/RenameTeamModal";
import EmojiPicker from "../../../components/elements/emojis/EmojiPicker";
import useRenameTeamMutation from "../../../hooks/mutations/useRenameTeamMutation";

const RenameTeamController = ({ team, onClose }) => {
  const [newTeamName, setNewTeamName] = useState(team.name);
  const [newEmoji, setNewEmoji] = useState(team.emoji);
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [renameTeamMutation] = useRenameTeamMutation();

  const handleSelectEmoji = (emoji) => {
    setNewEmoji(emoji.native);
    setShowEmojiPicker(false);
  };

  const handleRename = async () => {
    await renameTeamMutation({
      variables: {
        teamId: team.id,
        name: newTeamName,
        emoji: newEmoji,
      },
    });

    await onClose();
  };

  return (
    <RenameTeamModal
      teamName={newTeamName}
      emoji={newEmoji}
      onTeamNameChange={setNewTeamName}
      onShowEmojiPicker={() => setShowEmojiPicker(true)}
      emojiPicker={
        showEmojiPicker && (
          <EmojiPicker
            onSelect={handleSelectEmoji}
            onClose={() => setShowEmojiPicker(false)}
          />
        )
      }
      onRename={handleRename}
      onCancel={onClose}
    />
  );
};

export default RenameTeamController;
