import React, { useCallback, useContext, useState } from "react";
import QuestionScreen from "../../../screens/quiz/06/QuestionScreen";
import QuizNavigationController from "../QuizNavigationController";
import QuizChatController from "../QuizChatController";
import useCloseQuestionMutation from "../../../hooks/mutations/useCloseQuestionMutation";
import SessionDataContext from "../../../../contexts/SessionDataContext";
import QuizContext from "../../../../contexts/QuizContext";
import useTeamAnswerQuestionMutation from "../../../hooks/mutations/useTeamAnswerQuestionMutation";
import getTeamBreakoutAudio from "../AudioController/getTeamBreakoutAudio";
import usePusherChannel from "../../../hooks/pusher/usePusherChannel";
import useTeamLeadTypingAnswerMutation from "../../../hooks/mutations/useTeamLeadTypingAnswerMutation";
import useCountdownTimer from "../../../hooks/useCountdownTimer";
import TeamBreakoutController from "../TeamBreakoutController";
import TeamController from "../TeamController";
import sleep from "../../../../util/sleep";

const QuestionController = ({ resetButton }) => {
  const { myHostId } = useContext(SessionDataContext);
  const {
    myPlayer,
    question,
    myTeam,
    myLeaderName,
    myTeamAnswered,
    teams,
    assistingTeam,
  } = useContext(QuizContext);
  const [answerValue, setAnswerValue] = useState("");
  const [teamLeadTypingAnswerMutation] = useTeamLeadTypingAnswerMutation();
  const [teamAnswerQuestionMutation] = useTeamAnswerQuestionMutation();
  const [submitting, setSubmitting] = useState(false);
  const [questionDismissed, setQuestionDismissed] = useState(false);
  const [closeQuestionMutation] = useCloseQuestionMutation();

  const handleClose = useCallback(async () => {
    if (myHostId) {
      await sleep(2000);
      await closeQuestionMutation();
    } else if (myPlayer && myPlayer.leader && !myTeamAnswered) {
      await handleSubmitAnswer(answerValue);
    }
  }, [answerValue, myTeamAnswered]);

  const timeLeft = useCountdownTimer(question.closesAt, handleClose);

  const pusherChannel =
    myPlayer && !myPlayer.leader && myTeam && myTeam.id.replace(/-/g, "");

  const handlePusherEvent = async (eventName, data) => {
    if (eventName === "message") {
      setAnswerValue(data.text);
    }
  };

  usePusherChannel(pusherChannel, handlePusherEvent);

  const handleAnswerChange = (event) => {
    setAnswerValue(event.target.value);
    if (myPlayer && myPlayer.leader) {
      teamLeadTypingAnswerMutation({
        variables: {
          text: event.target.value,
        },
      });
    }
  };

  const handleSubmitAnswer = async (value) => {
    setSubmitting(true);
    await teamAnswerQuestionMutation({
      variables: {
        answerText: value,
      },
    });
  };

  const handleCloseQuestion = async () => {
    await closeQuestionMutation();
  };

  const isHost = Boolean(myHostId);

  return (
    <QuestionScreen
      audio={getTeamBreakoutAudio()}
      timeLeft={timeLeft}
      isHost={isHost}
      isLeader={myPlayer && myPlayer.leader}
      leaderName={myLeaderName}
      thisTeamAnswered={myTeamAnswered}
      number={question.number}
      questionText={question.text}
      answerText={question.answer}
      answerValue={answerValue}
      emoji={myTeam && myTeam.emoji}
      teamName={myTeam && myTeam.name}
      teamBreakout={
        (Boolean(assistingTeam) || !isHost) && <TeamBreakoutController />
      }
      allTeams={
        teams &&
        teams.map((team) => (
          <TeamController key={team.id} team={team} compact />
        ))
      }
      onAnswerChange={handleAnswerChange}
      onSubmitAnswer={handleSubmitAnswer}
      submitting={submitting}
      onDismiss={() => setQuestionDismissed(true)}
      dismissed={questionDismissed}
      onCloseQuestion={handleCloseQuestion}
      resetButton={resetButton}
      navigation={<QuizNavigationController />}
      chat={<QuizChatController />}
    />
  );
};

export default QuestionController;
